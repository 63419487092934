<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div>
      <CCard>
        <CCardBody>
          <div v-if="step === 0">
            <SearchList
              v-if="correspondent_updated.branch_id === null"
              ph="공장을 선택하십시오"
              :list="branch_list"
              :pageTotal="branch_page_total"
              @search="onSearchBranch"
              @focus="onFocusBranch"
              @select="onSelectBranch"
              @page="onPageBranch"
            />
            <CCard v-else>
              <CCardBody>
                <div class="my-2">공장</div>
                <CWidgetIcon :header="branch_name" color="primary">
                  <CIcon name="cil-factory" width="24"/>
                </CWidgetIcon>
                <CButton style="float: right;" color="danger" shape="pill" @click="onReset">초기화</CButton>
              </CCardBody>
            </CCard>
          </div>
          <div v-if="step === 1">
            <!-- <CInput
              label="코드"
              placeholder="거래처 코드"
              horizontal
              v-model="correspondent_updated.code"
            /> -->
            <CInput
              label="이름"
              placeholder="거래처 이름"
              horizontal
              v-model="correspondent_updated.name"
            />
            <div class="custom-input-line"
              v-for="(origin, index) in correspondent_updated.origins"
              :key="`origin-tag-${index}`"
            >
              <div class="custom-input-label">{{index === 0 ? '산지':' '}}</div>
              <div class="custom-input-data">
                <el-tag closable @close="onClose(index)">{{ origin.name }}</el-tag>
              </div>
            </div>
            <div class="custom-input-line" v-if="new_origin">
              <div class="custom-input-label">{{correspondent_updated.origins.length ? ' ':'산지'}}</div>
              <div class="custom-input-data">
                <el-tag :type="dangerIfDuplicated">{{ new_origin }}</el-tag>
              </div>
            </div>
            <CInput
              v-if="correspondent_updated.origins.length <= 9"
              :label="correspondent_updated.origins.length ? ' ':'산지'"
              placeholder="산지 이름"
              horizontal
              v-model="new_origin"
              @keyup="onOriginKey"
            />
            <CRow form class="form-group">
              <CCol tag="label" sm="3" class="col-form-label" />
              <CCol sm="9" class="form-inline">
                <div class="w-100 float-right" v-if="correspondent_updated.origins.length <= 9">
                  <CButton class="w-100 px-2" color="secondary" @click="onAddOrigin">
                    <svgicon name="add" width="20px" height="20px" />
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow form class="form-group">
              <CCol tag="label" sm="3" class="col-form-label">
                계약용적
              </CCol>
              <CCol sm="9" class="form-inline">
                <el-select v-model="correspondent_updated.quantity" multiple placeholder="Select" class="w-100">
                  <el-option label="16㎥" :value="16" />
                  <el-option label="16.5㎥" :value="16.5" />
                  <el-option label="17㎥" :value="17" />
                  <el-option label="17.5㎥" :value="17.5" />
                  <el-option label="18㎥" :value="18" />
                </el-select>
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </div>
    <template #header>
      <h6 class="modal-title">{{correspondent? '거래처 정보 변경': '새로운 거래처'}}  {{ ` - STEP (${+(step+1)}/2)` }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step===0" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===0" @click="onNext" color="primary">{{$t('button.next')}}</CButton>
      <CButton v-if="step===1 && myAuthorizationLevel.level < 100" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step===1 && myAuthorizationLevel.level >= 100" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CorrespondentForm',
  components: {
    SearchList
  },

  data() {
    return {
      show: false,

      branch_list: [],
      branch_search: null,
      branch_page_total: 1,
      branch_page_current: 1,

      correspondent: null,
      correspondent_updated: {
        id: null,
        name: null,
        // code: null,
        origin: null, // '산지 A-1,산지 A-2,산지 B-3'
        origins: [],  // [{id: 1, name: '산지 A-1'}, {id: 2, name: '산지 A-2'}, {id: 3, name: '산지 B-3'}}]
        quantity: [],
        branch_id: null,
      },
      branch_name: null,
      new_origin: null,

      notification: '',
      dismissCountDown: 0,
      step: 0
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    ...mapState([
      'capability'
    ]),
    dangerIfDuplicated() {
      if (this.correspondent_updated.origins.findIndex(el => el.name === this.new_origin) >= 0) {
        return 'danger';
      }
      if (this.new_origin.length > 32) {
        return 'danger';
      }
      return '';
    }
  },
  methods: {
    open(correspondent) {
      this.onCancel();
      this.correspondent = correspondent || null;
      this.initialize();
      this.show = true;
    },
    initialize() {
      if (this.correspondent) {
        this.correspondent_updated = {
          id: this.correspondent.id,
          name: this.correspondent.name,
          // code: this.correspondent.code,
          origin: this.correspondent.origin,
          origins: this.correspondent.origins,
          branch_id: this.correspondent.branch_id,
          quantity: this.correspondent.quantity_contract
        };
        this.branch_name = this.correspondent.branch_name
      } else {
        this.correspondent_updated = {
          id: null,
          name: null,
          // code: null,
          origin: null,
          origins: [],
          branch_id: null
        };
        this.branch_name = null;
      }
      if (this.myAuthorizationLevel.level >= 100) {
        this.correspondent_updated.branch_id = this.capability.user_profile.branch_id;
        this.step = 1;
      }
    },
    onCancel() {
      this.show = false;
      this.correspondent = null;
      this.correspondent_updated = {
        id: null,
        name: null,
        // code: null,
        origin: null,
        origins: [],
        branch_id: null
      };
      this.new_origin = null;
      this.step = 0;

      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_current = 1;
      this.branch_page_total = 1;
    },
    onSubmit() {
      // if (!this.correspondent_updated.branch_id) {
      //   this.notification = '공장을 선택하십시오';
      //   this.dismissCountDown = 3;
      //   return;
      // }
      // if (!this.correspondent_updated.code) {
      //   this.notification = '거래처 코드를 입력하십시오';
      //   this.dismissCountDown = 3;
      //   return;
      // }
      // if (this.correspondent_updated.code.length > 32) {
      //   this.notification = '거래처 코드가 너무 깁니다';
      //   this.dismissCountDown = 3;
      //   return;
      // }
      if (!this.correspondent_updated.name) {
        this.notification = '거래처 이름을 입력하십시오';
        this.dismissCountDown = 3;
        return;
      }
      if (this.correspondent_updated.name.length > 64) {
        this.notification = '거래처 이름이 너무 깁니다';
        this.dismissCountDown = 3;
        return;
      }

      if (this.new_origin && this.dangerIfDuplicated !== 'danger') {
        this.correspondent_updated.origins.push({name: this.new_origin});
      }

      this.$emit('payload', this.correspondent_updated);
      this.onCancel();
      this.show = false;
    },
    onNext() {
      if (!this.correspondent_updated.branch_id) {
        this.notification = "공장을 선택하십시오";
        this.dismissCountDown = 3;
        return;
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },

    getBranches(){
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.branch_search,
        page: this.branch_page_current,
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          this.branch_page_total = result.data.page.total;
          this.branch_page_current = result.data.page.current;
        })
        .catch(error => {
          console.error(error);
        });
    },

    onSearchBranch(text) {
      this.branch_search = text;
      this.getBranches();
    },
    onFocusBranch() {
      this.getBranches();
    },
    onPageBranch(p) {
      this.branch_page_current = p;
      this.getBranches();
    },
    onSelectBranch(id) {
      this.correspondent_updated.branch_id = id;
      this.branch_name = this.branch_list.find(el => el.id === id).name;
    },
    onAddOrigin() {
      if (this.new_origin) this.new_origin = this.new_origin.replace(',', '');
      if (!this.new_origin) {
        this.notification = '산지 이름을 입력하십시오';
        this.dismissCountDown = 3;
        return;
      }
      if (this.correspondent_updated.origins.findIndex(el => el.name === this.new_origin) >= 0) {
        this.notification = '산지 이름이 중복되었습니다';
        this.dismissCountDown = 3;
        return;
      }
      if (this.new_origin.length > 32) {
        this.notification = '산지 이름은 32글자 이하로 설정하십시오';
        this.dismissCountDown = 3;
        return;
      }
      this.correspondent_updated.origins.push({name: this.new_origin});
      this.new_origin = null;
    },
    onClose(index) {
      this.correspondent_updated.origins.splice(index, 1);
    },
    onOriginKey(e) {
      if (e.key !== 'Enter' && e.key !== ',') return;
      this.onAddOrigin();
    },
    onReset(){
      this.correspondent_updated.branch_id = null;
      this.correspondent_updated.branch_name = null;
      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_total = 1;
      this.branch_page_current = 1;
    }
  }
}
</script>
