<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />
    <CorrespondentForm ref="FormModal" @payload="onPayload" />
    <Search class="my-4" @search="onSearch">
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="list"
            :fields="FIELDS"
            header-icon="cil-truck"
            add-btn
            add-btn-text="추가"
            edit-btn
            :view-btn="false"
            :import-btn="false"
            :count="count"
            caption="거래처"
            hover
            small
            fixed
            @add="onAdd"
            @delete="onDelete"
            @edit="onEdit"
          />
        </CCol>
      </CRow>
      <CRow class="justify-content-end" v-if="pageTotal > 1">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
    </Search>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import CTableWrapper from '@/components/EdxTable';
import CorrespondentForm from './CorrespondentForm.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryCorrespondents',
  components: {
    Search,
    CTableWrapper,
    ConfirmModal,
    CorrespondentForm
  },
  data () {
    return {
      FIELDS: [],

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      searchText: null,
      count: 0,
      list: []
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ])
  },
  mounted() {
    this.initFetch()
  },
  methods: {
    initFetch() {
      if (this.myAuthorizationLevel.level < 100) {
        this.FIELDS.push({key: 'branch_name', label: '공장'});
      }
      this.FIELDS.push({key: 'name', label: '이름'});
      this.FIELDS.push({key: 'origin', label: '산지'});
      this.FIELDS.push({key: 'quantity', label: '계약용적'});
      this.FIELDS.push({key: 'ops', label: '작업', _style: 'width:100px;'});
      this.getList();
    },
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/correspondents/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.list.map(el => {
            el.origin = el.origins.map(elem => elem.name).join(',');
            el.quantity = el.quantity_contract.join('㎥, ');
            if (el.quantity) {
              el.quantity = el.quantity + '㎥';
            }
          });
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onAdd() {
      this.$refs.FormModal.open();
    },
    onEdit(id) {
      const correspondent = this.list.find(el => el.id === id);
      this.$refs.FormModal.open(correspondent);
    },
    onPayload(payload) {
      if (payload.id) {
        axios.put(`/api/delivery/correspondents/${payload.id}/`, payload)
          .then(() => {
            this.getList();
            this.$notify.success({
              title: '완료',
              message: '항목이 수정되었습니다',
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: '오류',
              message: `요청 실패: ${error.response.data.error}`,
              offset: 30
            });
          })
        return;
      }
      axios.post(`/api/delivery/correspondents/`, payload)
        .then(() => {
          this.getList();
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `요청 실패: ${error.response.data.error}`,
            offset: 30
          });
        })
    },

    onUpdatePage(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    onDelete(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onConfirmed(payload) {
      axios.delete(`/api/delivery/correspondents/${payload}/`)
        .then(() => {
          const del_name = this.list.find(el => el.id === payload).name;
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `거래처 '${del_name}'가 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
        });
    },

    onUpdateCate(payload) {
      console.log('onUpdateCate', payload);
      this.initFetch();
    }
  }
}
</script>
